import PropTypes from 'prop-types'
import Head from 'next/head'
import { memo } from 'react'
import { Transition } from 'react-transition-group'
import { contentStyle, contentTransitionStyles } from '@/utils/transition'
import Footer from '../footer'
import Header from '../header'
import BottomBar from '../bottomBar'
import Sportsidebar from '../sportsidebar'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { APP_URL } from '@/constants/environment'
import sidebarSelector from '@/redux/selectors/siderbar'

const SharedLayout = ({
  children,
  title,
  showIcons,
  showRightSidebar,
  className = '',
  footer = true,
  sidebar = true,
  headerSpace = true,
}) => {
  const router = useRouter()
  const { isOpen } = useSelector(sidebarSelector)

  let conditionalWidth = ''
  let conditionalFooterWidth = ''

  if (sidebar && isOpen) {
    conditionalWidth += 'w-[calc(100%-11rem)]'
  } else if (sidebar && !isOpen) {
    conditionalWidth += 'w-full sm:w-[calc(100%-5rem)]'
  } else if (!sidebar) {
    conditionalWidth += 'w-full'
  }

  if (sidebar && isOpen && showRightSidebar) {
    conditionalFooterWidth += 'lg:w-[calc(100%-18rem)] w-full'
  } else if (sidebar && !isOpen && !showRightSidebar) {
    conditionalFooterWidth += 'lg:w-full w-full'
  } else if (sidebar && !isOpen && showRightSidebar) {
    conditionalFooterWidth += 'lg:w-[calc(100%-18rem)] w-full'
  } else if (sidebar && isOpen && !showRightSidebar) {
    conditionalFooterWidth += 'lg:w-full w-full'
  } else if (!sidebar && !isOpen && showRightSidebar) {
    conditionalFooterWidth += 'lg:w-[calc(100%-18rem)] w-full'
  } else if (!sidebar) {
    conditionalFooterWidth += 'w-full'
  }
  return (
    <div className="min-h-screen hero-backgroud overflow-x-hidden">
      <Head>
        <title>{`${title ? title + ' ' + '|' : ''} Lilbit`}</title>
        <link rel="icon" href="/svgs/favicon.svg" />
        <meta
          name="description"
          content="Find the best casino, sports, bets, plinko, and games on Lilbit. Sign up now to get started."
        />
        <meta
          name="keywords"
          content="casino, sports, bets, plinko, games, betting, gambling, online casino, sportsbook, esports, virtual sports, live casino, table games, slots, roulette, blackjack, poker, baccarat"
        />
        <meta
          property="og:title"
          content="Best Casino, Sports, Bets, Plinko, and Games | Lilbit"
        />
        <meta
          property="og:description"
          content="Find the best casino, sports, bets, plinko, and games on Lilbit. Sign up now to get started."
        />
        <meta property="og:image" content="/img/OpenGraph.png" />
        <meta property="og:url" content={APP_URL} />

        <link rel="canonical" href={APP_URL} />
        <h1 className="hidden">
          lilbit,casino, sports, bets, plinko, games, betting, gambling, online
          casino, sportsbook, esports, virtual sports, live casino, table games,
          slots, roulette, blackjack, poker, baccarat
        </h1>
      </Head>
      <div className="flex flex-row">
        {sidebar && (
          <Sportsidebar
            isOpen={isOpen}
            showIcons={showIcons}
            footballIcon={true}
          />
        )}
        <div className={`flex flex-1 min-h-screen flex-col`}>
          <div className="flex-1">
            <Header
              isOpen={isOpen}
              isSidebarOpen={sidebar}
              width={conditionalWidth}
              showIcons={showIcons}
            />
            <div
              className={`${
                router.pathname.includes('sports')
                  ? 'h-[152px] sm:h-[76px]'
                  : 'h-[71px]'
              }  w-full`}
            />
            <Transition in={isOpen} timeout={300}>
              {(state) => (
                <main
                  style={{
                    ...contentStyle(300),
                    ...contentTransitionStyles[state],
                  }}
                  className={`${className} relative ${conditionalWidth} ml-auto `}
                >
                  {children}
                </main>
              )}
            </Transition>
          </div>

          {footer && (
            <Footer
              width={conditionalWidth}
              conditionalFooterWidth={conditionalFooterWidth}
              isOpen={isOpen}
              isSidebarOpen={sidebar}
              isHome={router.pathname.includes('home') && true}
            />
          )}

          <BottomBar />
        </div>
      </div>
    </div>
  )
}

SharedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default memo(SharedLayout)
