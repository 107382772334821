import { getLeagueByName } from '@/constants/countries-leagues'
import sidebarSelector from '@/redux/selectors/siderbar'
import sportsSelector from '@/redux/selectors/sports'
import {
  resetRoute,
  setActiveLeague,
  setActiveRoute,
  setActiveSport,
  setSecActiveSport,
  setManuallyActive,
  setSidebarLoading,
  updateVisitedIndex,
  toggleChatBar,
} from '@/redux/slices/sidebar'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function useSidebar() {
  const {
    isOpen,
    activeRoute,
    sports,
    chat,
    leagues,
    activeSport,
    activeLeague,
    visitedLeagues,
  } = useSelector(sidebarSelector)
  const { games: gamesData } = useSelector(sportsSelector)

  const getLeagueWithId = useCallback(
    ({ sport, leagueId }) => {
      const leagues = gamesData ? gamesData[sport?.toLowerCase()] : {}
      const league = Object.keys(leagues || {}) || []

      const allItems = {}
      if (league.length) {
        league?.forEach((league) => {
          const leagueItems = leagues[league]
          leagueItems.forEach((item) => {
            allItems[item?.id] = getLeagueByName(league)
          })
        })
      }

      return allItems[leagueId]
        ? allItems[leagueId]?.name
        : typeof leagues === 'object' && Object.keys(leagues)[0]
    },
    [gamesData],
  )

  const router = useRouter()
  const dispatch = useDispatch()

  const setActiveSportHandler = (sport) => {
    if (sport === activeSport) return
    // dispatch(setActiveSport(sport))
    router.push(`/sports/${sport}`)
    //dispatch(setSidebarLoading(true))
  }

  const arrayFromUrl = (path = '') => {
    const url = path || window?.location?.pathname
    const urlArray = url.split('/')
    urlArray.shift()
    return urlArray
  }

  const setActiveLeagueHandler = (league) => {
    if (league === activeLeague) {
      dispatch(updateVisitedIndex(league))
    } else {
      dispatch(setActiveLeague(league))
    }
  }

  const routeParams = (route) => {
    const newArr = route.split('?')[1]?.split('&')
    if (!newArr) {
      return {
        sport: '',
      }
    }
    const params = newArr?.reduce((acc, item) => {
      const [key, value] = item?.split('=')
      acc[key] = value
      return acc
    }, {})

    return params
  }

  useEffect(() => {
    const handleRouteChange = (url) => {
      const { sport } = routeParams(url)
      if (sport) {
        return setTimeout(() => {
          dispatch(
            setManuallyActive({
              sport: sport,
              league: '',
            }),
          )
        }, 100)
      }

      const route = arrayFromUrl(url)
      dispatch(setActiveRoute(route.join('/')))
      if (route?.length === 2) {
        dispatch(setActiveSport(route[1]))
      } else if (route[2]) {
        dispatch(
          setManuallyActive({
            sport: route[1],
            league: getLeagueWithId({
              sport: route[1],
              leagueId: route[2],
            }),
          }),
        )
      } else {
        dispatch(resetRoute())
      }
    }

    handleRouteChange(window?.location.pathname + window?.location.search)
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    const route = arrayFromUrl()
    if (leagues && sports?.length && route?.length === 2) {
      dispatch(setSecActiveSport(route[1]))
    } else if (route[2]) {
      dispatch(
        setManuallyActive({
          sport: route[1],
          league: getLeagueWithId({
            sport: route[1],
            leagueId: route[2],
          }),
        }),
      )
    } else {
      dispatch(resetRoute())
    }
  }, [leagues, sports])

  return {
    isOpen,
    chat,
    sports,
    leagues,
    activeRoute,
    activeSport,
    activeLeague,
    visitedLeagues,
    setChat: () => dispatch(toggleChatBar()),
    setActiveSport: setActiveSportHandler,
    setActiveLeague: setActiveLeagueHandler,
    setManuallyActive: (data) => dispatch(setManuallyActive(data)),
  }
}
