import NextImage from 'next/image'
import dynamic from 'next/dynamic'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function BlockUserModal({ onSend, isOpen, message, setIsOpen }) {
  const onClose = () =>
    setIsOpen({
      status: false,
      message: null,
    })

  const onHandle = () => {
    const { uid, username } = message
    onSend({
      [uid]: {
        uid,
        username,
      },
    })
  }

  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto max-w-xl mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">Block User</h2>
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <NextImage
          width={20}
          height={20}
          src="/svgs/x-close.svg"
          alt="close button"
        />
      </div>

      <p className="mt-5 text-left">
        Blocking{' '}
        <span className="underline underline-offset-2 text-red-400">
          {message?.username}
        </span>{' '}
        will prevent you from receiving any further messages from them, and you
        won&apos;t be able to see their messages. Are you sure you want to block{' '}
        <span className="underline underline-offset-2 text-red-400">
          {message?.username}
        </span>
        ?
      </p>

      <div className="flex justify-end items-center mt-2">
        <button
          onClick={onHandle}
          className="flex bg-cs-green px-5 py-1 rounded-md"
        >
          Block
        </button>
      </div>
    </Model>
  )
}
