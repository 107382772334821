export const nameColors = {
  white: '#ffffff',
  gray: '#6B7280',
  green: '#3bc472',
  blue: '#0d74f2',
  red: '#c20000',
  purple: '#A855F7',
  orange: '#F97316',
}

export const textColors = {
  gray: '#374151',
  green: '#15803D',
  blue: '#1D4ED8',
  red: '#B91C1C',
  purple: '#7E22CE',
  orange: '#EA580C',
}
