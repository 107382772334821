import React from 'react'

const StringChip = ({ string, time, className }) => (
  <button
    className={`${
      className ? className : 'bg-white/10'
    } px-2.5 py-0.5 rounded-2xl font-medium text-sm ${
      time && 'flex flex-row items-center gap-2'
    }`}
  >
    {time && (
      <p
        className={`w-1.5 h-1.5 ${
          className ? 'bg-white' : 'bg-[#F7941D]'
        } rounded-full`}
      >
        &nbsp;
      </p>
    )}
    {string || ''}
  </button>
)

export default StringChip
