import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import NextLink from 'next/link'
import { Menu, Popover, Transition } from '@headlessui/react'
import { XIcon, ChatAltIcon } from '@heroicons/react/outline'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { firebaseLogout, getProfileSetting } from '@/firebase/utils'
import { contentStyle, contentTransitionStyles } from '@/utils/transition'
import { clearBetsData } from '@/redux/slices/betSlips'
import { changePastBet } from '@/redux/slices/bets'
import { Transition as ReactTransaction } from 'react-transition-group'
import { useState } from 'react'
import NovuNotification from '../NovuNotification'
import { ChangeMenuType } from './MenuType'
import { getAuth } from 'firebase/auth'
import NextImage from '@/utils/Image'
import HeaderButton from '@/components/button/HeaderButton'
import ConnectWallet from '@/components/connectWallet'
import StringChip from '@/components/stringChip'
import { tabNavigation } from '@/utils/helper'
import walletSelector from '@/redux/selectors/wallet'
import useSidebar from '@/hooks/useSidebar'
import { getSportByName } from '@/constants/countries-leagues'
import { SecondaryIconButton } from '../sportsidebar'
import { useMediaQuery } from 'react-responsive'
import { changeMenuDisplayType } from '@/redux/slices/betSlips'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'

import Chat from '../chat'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { FormattedMessage, useIntl } from 'react-intl'

// const navigation = [
//   {
//     label: 'Home',
//     icon: '/svgs/home-hashtag.svg',
//     href: '/home',
//     page: 'home',
//   },
//   {
//     label: 'Casino',
//     icon: '/svgs/Casino.svg',
//     href: '/casino/plinko',
//     page: 'casino',
//   },
//   {
//     label: 'Sports',
//     icon: '/svgs/gameHeader.svg',
//     href: '/sports/popular-events',
//     page: 'sports',
//   },
//   {
//     label: 'Leaderboard',
//     icon: '/svgs/ranking.svg',
//     href: '/leaderboard',
//     page: 'leaderboard',
//   },
// ]

const navigation = [
  {
    label: 'navbar.home',
    defaultLabel: 'Home',
    icon: '/svgs/home-hashtag.svg',
    href: '/home',
    page: 'home',
  },
  {
    label: 'navbar.casino',
    defaultLabel: 'Casino',
    icon: '/svgs/Casino.svg',
    href: '/casino/plinko',
    page: 'casino',
  },
  {
    label: 'navbar.sports',
    defaultLabel: 'Sports',
    icon: '/svgs/gameHeader.svg',
    href: '/sports/popular-events',
    page: 'sports',
  },
  {
    label: 'navbar.leaderboard',
    defaultLabel: 'Leaderboard',
    icon: '/svgs/ranking.svg',
    href: '/leaderboard',
    page: 'leaderboard',
  },
  {
    label: 'navbar.predictionMarket',
    defaultLabel: 'Prediction Market',
    icon: '/svgs/predictionMarket.svg',
    href: '/prediction-market',
    page: 'predictionMarket',
  },
]

const notifications = [
  {
    index: 1,
    badge: 'News',
    title: 'How to win poker with eyes close.',
    icon: '/svgs/newsicon.svg',
    pin: '/svgs/goldpin.svg',
    bodytext:
      'Numquam quae quibusdam illo neque voluptatem quam repellat. Provident tempora ...',
    className: 'bg-white/10 text-[#F7941D] text-xs',
  },
  {
    index: 2,
    badge: 'Betting',
    title: 'Your bet wins!',
    icon: '/svgs/footballicon.svg',
    pin: '/svgs/pin.svg',
    bodytext:
      'You won the bet on Manchester United against Chelsea. Claim now!',
    className: 'bg-white/10 text-[#008000] text-xs',
  },
  {
    index: 3,
    badge: 'Maintenance',
    title: 'Routine maintenance',
    icon: '/svgs/toggleicon.svg',
    pin: '/svgs/pin.svg',
    bodytext: 'Routine maintenance until January 10, 2023 at 10:00 BST.',
    className: 'bg-white/10 text-[#F7941D] text-xs',
  },
  {
    index: 4,
    badge: 'Maintenance',
    title: 'Routine maintenance',
    icon: '/svgs/toggleicon.svg',
    pin: '/svgs/pin.svg',
    bodytext: 'Routine maintenance until January 10, 2023 at 10:00 BST.',
    className: 'bg-white/10 text-[#F7941D] text-xs',
  },
]

const Model = dynamic(() => import('../chat/chatModal'), {
  ssr: false,
})

function Header({
  isOpen,
  isSidebarOpen,
  width,
  isConnectVisible = false,
  showIcons = true,
}) {
  const dispatch = useDispatch()
  const [navigations, setNavigations] = useState(tabNavigation)
  const chatEnabled = useFeatureIsOn('chat')
  const [profile, setProfile] = useState({})
  const router = useRouter()
  const auth = getAuth()
  const containerRef = useRef(null)
  const uniqueNavigation = [...new Set([...navigations])]
  const { isAuthenticated: isUserLoggedIn, user } = useSelector(authSelector)
  const { betslipsOpenMobile, menuDisplayType } = useSelector(betSlipsSelector)
  const { internalWallet } = useSelector(walletSelector)
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const { activeRoute, sports, activeSport, chat, setChat, ...rest } =
    useSidebar()
  const sportsData = useMemo(() => {
    return (
      (sports?.length && sports?.map((sport) => getSportByName(sport))) || []
    )
  }, [sports])
  const sortedSports = useMemo(() => {
    return sportsData?.sort((a, b) => a.index - b.index)
  }, [sportsData])

  const logoutHandler = async () => {
    dispatch(clearBetsData())
    dispatch(changePastBet(false))
    await firebaseLogout()
  }

  const signinHandler = () => {
    router.push('/login')
  }

  useEffect(() => {
    getProfile()
  }, [])

  async function getProfile() {
    const res = await getProfileSetting()
    setProfile(res)
    if (res?.roleType === 'admin') {
      const ind = navigations.findIndex((nav) => nav.name === 'Enquiries')
      if (ind === -1) {
        setNavigations([
          ...new Set([
            ...navigations,
            {
              name: 'Enquiries',
              index: 6,
              icon: '/svgs/enquiry.svg',
              href: '/settings?tab=enquiries',
            },
          ]),
        ])
      }
    }
  }

  const handleLeftArrowClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100
    }
  }

  const handleRightArrowClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100
    }
  }

  const intl = useIntl()

  return (
    <ReactTransaction in={isOpen} timeout={300}>
      {(state) => (
        <Popover
          style={{
            ...contentStyle(300),
            ...contentTransitionStyles[state],
          }}
          className={`fixed ${width} ml-auto right-0 bg-[#000000]/5 backdrop-blur-md z-30 ${
            sm && betslipsOpenMobile && 'hidden'
          }`}
        >
          <div className="w-full flex items-center justify-between px-6 sm:px-8 py-4 flex-nowrap gap-2 ">
            <div className={`items-center gap-2 hidden md:flex`}>
              {!isSidebarOpen && (
                <Link href="/home" className="relative">
                  <NextImage
                    src="/img/lil-bit-logo.svg"
                    alt="logo"
                    className="mr-8 cursor-pointer"
                    width={105}
                    height={105}
                  />
                </Link>
              )}
              {navigation.map((item, index) => (
                <HeaderButton key={index} {...item} header />
              ))}
              {profile?.roleType === 'admin' && auth.currentUser && (
                <ChangeMenuType
                  activeMenuType={menuDisplayType}
                  onChange={(val) => dispatch(changeMenuDisplayType(val))}
                />
              )}
            </div>

            <div className={`flex gap-x-4 items-center md:hidden`}>
              <Popover.Button as={Fragment}>
                <div
                  className={`text-white rounded-lg cursor-pointer w-10 h-10 flex items-center justify-center`}
                >
                  <NextImage
                    src="/svgs/sideBarMenu.svg"
                    width={20}
                    height={20}
                    alt="wallet"
                  />
                </div>
              </Popover.Button>
              <Link href="/home" className="relative block sm:hidden">
                <NextImage
                  src="/img/lil-bit-logo.svg"
                  alt="logo"
                  className="mr-8 cursor-pointer"
                  width={85}
                  height={85}
                />
              </Link>
            </div>

            <div className={`items-center gap-2 flex flex-nowrap`}>
              <div className={`mt-1 items-center gap-2 hidden sm:flex`}>
                {isConnectVisible && <ConnectWallet />}
              </div>

              {auth.currentUser && (
                <div
                  className="h-[40px] sm:h-[30px] w-[139px] sm:w-[30px] bg-[#fff]/10 sm:bg-inherit gap-x-1 flex sm:hidden items-center justify-between rounded-3xl px-2 sm:px-0 cursor-pointer"
                  onClick={() => router.push('/settings?tab=wallet')}
                >
                  <div className="flex-1 font-bold text-md mt-1 ml-1 overflow-hidden">
                    {`$${internalWallet.balanceUsd || 0}`}
                  </div>
                  <div className="relative ">
                    <NextImage
                      src="/img/wallet.webp"
                      width={20}
                      height={20}
                      alt="wallet"
                    />
                  </div>
                </div>
              )}
              {chatEnabled && (
                <button
                  onClick={setChat}
                  type="button"
                  class="focus:outline-none font-medium rounded-lg text-sm text-center inline-flex items-center"
                >
                  <ChatAltIcon className="w-6 h-6 text-gray-300" />
                </button>
              )}
              {auth.currentUser && <NovuNotification />}
              {/*Notification panel*/}
              <Menu as="div">
                <Transition
                  timeout={300}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute bg-[#0E161B] backdrop right-8 mt-4 h-[535px] pt-4  w-[354px] space-y-1 origin-top-right rounded-xl overflow-x-hidden overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-[#1B2126] shadow-lg focus:outline-none  border border-white/10">
                    <div className="flex mb-4 flex-row justify-between relative">
                      <h2 className="pl-4 text-lg font-medium leading-7 font-sans  ">
                        Notifications
                      </h2>
                      <NextImage
                        fill
                        src="/svgs/x-close.svg"
                        alt="cross svg"
                        className="cursor-pointer pr-8"
                      />
                    </div>
                    {notifications.map((tab, index) => (
                      <div
                        className="h-[158px] w-[320px]  bg-[#1B2126] space-y-2 rounded-xl mx-4 my-4"
                        key={index}
                      >
                        <div className="flex p-4">
                          <div className="flex items-start relative">
                            <NextImage width={22} height={22} src={tab.icon} />
                          </div>

                          <div className="flex flex-col flex-1 ">
                            <div className="flex justify-between">
                              <div className="pl-2 flex-wrap">
                                <StringChip
                                  string={tab.badge}
                                  className={tab.className}
                                />
                              </div>
                              <div className="relative">
                                <NextImage
                                  height={13}
                                  width={13}
                                  src={tab.pin}
                                  alt="pic icon"
                                />
                              </div>
                            </div>
                            <div className="text-sm font-normal leading-5 font-sans pl-3 pt-2">
                              {tab.title}
                            </div>
                            <div className="text-xs font-normal leading-5 font-sans pl-3 pt-2 text-[#8D9193] ">
                              {tab.bodytext}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* Profile dropdown */}
              {auth.currentUser ? (
                <Menu as="div" className="relative ml-3 hidden sm:block ">
                  <div>
                    <Menu.Button as={Fragment}>
                      <div className="w-10 h-10 rounded-full cursor-pointer relative">
                        <NextImage
                          fill
                          sizes="(max-width: 768px) 100vw,
                          (max-width: 1200px) 50vw,
                          33vw"
                          src={
                            profile?.photoPATH
                              ? profile?.photoPATH
                              : '/img/blank-profil-picture.webp'
                          }
                          className="object-contain rounded-full"
                        />
                      </div>
                    </Menu.Button>
                  </div>
                  {/* PopUp of Profile */}
                  <Transition
                    timeout={300}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-[100] bg-black backdrop right-0 mt-4 w-fit space-y-1 origin-top-right rounded-xl  pt-4 shadow-lg focus:outline-none overflow-y-scroll max-h-[80vh]">
                      <div className="overflow-y-auto max-h-[calc(80vh - 4rem)]">
                        {uniqueNavigation.map((tab, index) => {
                          return (
                            <Menu.Item key={index + tab.name}>
                              <NextLink href={tab?.href}>
                                <div
                                  className={`flex flex-row items-center rounded-lg cursor-pointer py-3 px-4 mx-3 gap-4 min-w-fit text-white  ${
                                    tab.active
                                      ? 'bg-white/10'
                                      : 'hover:bg-white/10'
                                  }`}
                                >
                                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-white/5">
                                    <NextImage
                                      src={tab.icon}
                                      width={22}
                                      height={22}
                                    />
                                  </div>
                                  <span className="text-base font-medium">
                                    <FormattedMessage
                                      id={tab.name}
                                      defaultMessage={tab.defaultName}
                                    />
                                  </span>
                                </div>
                              </NextLink>
                            </Menu.Item>
                          )
                        })}
                      </div>
                      <div className="sticky bottom-0 bg-black ">
                        <Menu.Item>
                          <>
                            <div className="h-2.5" />
                            {isUserLoggedIn ? (
                              <div
                                className={`flex flex-row items-center justify-center cursor-pointer py-7 px-4 gap-2 min-w-fit text-[#008000] bg-white/10 relative`}
                                onClick={logoutHandler}
                              >
                                <span className="text-base font-medium">
                                  <FormattedMessage
                                    id="navbar.authUser.logout"
                                    defaultMessage="Logout"
                                  />
                                </span>
                                <NextImage
                                  width={20}
                                  height={20}
                                  src="/svgs/logout.svg"
                                  className="w-5 h-5"
                                />
                              </div>
                            ) : (
                              <div
                                className={`text-center cursor-pointer py-7 px-4 gap-2 min-w-fit text-[#008000] bg-white/10`}
                                onClick={signinHandler}
                              >
                                <span className="text-base font-medium">
                                  Sign in
                                </span>
                              </div>
                            )}
                          </>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <button
                  onClick={() => router.push('/login')}
                  className="mt-1 inline-block bg-[#008000] py-2 px-2 sm:px-3 rounded-lg w-[auto] sm:w-[100px]"
                >
                  <FormattedMessage id="navbar.login" defaultMessage="Login" />
                </button>
              )}
            </div>
          </div>

          {/* popUp for mobile here */}
          <Transition
            timeout={300}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className={`absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden`}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-[#0e1411] divide-y-2 divide-gray-50">
                <div className="pt-5 px-5">
                  <div>
                    <Popover.Button as={Fragment}>
                      <div
                        className={`text-white rounded-lg cursor-pointer w-10 h-10 flex items-center justify-center`}
                      >
                        <XIcon className="w-5 h-5 " />
                      </div>
                    </Popover.Button>
                  </div>
                  <div className="my-6 sm:mt-8">
                    <nav>
                      <div className="grid gap-2 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                        {[...navigation].map((item, index) => (
                          <HeaderButton key={index} {...item} />
                        ))}
                        {isConnectVisible && <ConnectWallet />}
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  {isUserLoggedIn ? (
                    <HeaderButton
                      label={'Logout'}
                      active
                      centered
                      icon="/svgs/logout.svg"
                      onClick={logoutHandler}
                    />
                  ) : (
                    <div>
                      <NextLink href="/register">
                        <div className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-white/10 hover:bg-white/5"></div>
                        Sign up
                      </NextLink>
                      <p className="mt-6 text-center text-base font-medium text-white/50">
                        Existing user?{' '}
                        <NextLink href="/login">
                          <div className="text-white hover:text-white/80">
                            Sign in
                          </div>
                        </NextLink>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
          {router.pathname === '/sports/[sport]' ||
          router.pathname === '/sports/popular-events' ? (
            <div className="flex sm:hidden px-2 relative items-center justify-between">
              <div
                className={`flex items-center justify-center ${
                  activeSport === 'soccer'
                    ? 'w-[45px] h-[45px]'
                    : 'w-[30px] h-[30px]'
                } bg-white/10 rounded-full cursor-pointer relative mr-2`}
              >
                <NextImage
                  fill
                  src="/svgs/arrow-left.svg"
                  onClick={handleLeftArrowClick}
                />
              </div>
              <div
                className="overflow-auto whitespace-nowrap"
                ref={containerRef}
              >
                <div
                  className={`flex ${
                    activeSport === 'soccer' ? 'w-[230vw]' : 'w-[120vw]'
                  }`}
                >
                  {showIcons &&
                    sortedSports?.length > 0 &&
                    sortedSports?.map((sport, index) => (
                      <SecondaryIconButton
                        key={index}
                        sport={sport}
                        activeSport={activeSport}
                        appendedData={rest}
                        isOpen={isOpen}
                        onClick={() => rest?.setActiveSport(sport?.name)}
                        setActiveLeague={rest?.setActiveLeague}
                        sm={sm}
                      />
                    ))}
                </div>
              </div>
              <div
                className={`flex items-center justify-center ${
                  activeSport === 'soccer'
                    ? 'w-[45px] h-[45px]'
                    : 'w-[30px] h-[30px]'
                } bg-white/10 rounded-full cursor-pointer relative ml-2`}
              >
                <NextImage
                  fill
                  src="/svgs/arrow-right.svg"
                  onClick={handleRightArrowClick}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {chatEnabled && (
            <Model onClose={setChat} isOpen={chat}>
              <Chat user={user} paymail={internalWallet?.paymail} />
            </Model>
          )}
        </Popover>
      )}
    </ReactTransaction>
  )
}

export default React.memo(Header)
