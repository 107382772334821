import NextImage from 'next/image'
import dynamic from 'next/dynamic'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function ReportModal({
  onSend,
  isOpen,
  message,
  setIsOpen,
  reason,
  setReason,
}) {
  const onClose = () =>
    setIsOpen({
      status: false,
      message: null,
    })
  const onHandle = () => {
    onSend()
  }

  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto max-w-xl mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">Report User</h2>
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <NextImage
          width={20}
          height={20}
          src="/svgs/x-close.svg"
          alt="close button"
        />
      </div>

      <ul className="mt-5 text-left flex flex-col space-y-2">
        <li>
          <span className="text-gray-400">Username:</span>{' '}
          <span className="">{message?.username}</span>
        </li>
        <li>
          <span className="text-gray-400">Message:</span>{' '}
          <span className="">{message?.text}</span>
        </li>
      </ul>

      <label
        for="message"
        class="block mb-2 mt-2 text-left text-sm font-medium text-gray-400"
      >
        Could you please explain in a few words why you feel uncomfortable? Your
        feedback is important to us in ensuring a positive and respectful
        experience for everyone. Thank you for sharing your thoughts.
      </label>
      <textarea
        id="message"
        rows="4"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        class="block p-2.5 w-full text-sm rounded-lg border-none outline-none focus:outline-none focus:outline-offset-0 bg-gray-700 border-gray-600 placeholder-gray-400 text-white"
        placeholder="Write your thoughts here..."
      ></textarea>

      <div className="flex justify-end items-center mt-2">
        <button
          onClick={onHandle}
          className="flex bg-cs-green px-5 py-1 rounded-md"
        >
          Send
        </button>
      </div>
    </Model>
  )
}
