import NextImage from '@/utils/Image'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef } from 'react'

const Model = ({
  isOpen,
  onClose,
  modelBG,
  className,
  showCloseIcon,
  scroll = false,
  children,
}) => {
  const scrollbarRef = useRef(null)
  useEffect(() => {
    if (scroll) {
      setTimeout(() => {
        scrollbarRef.current?.scrollIntoView(true)
      }, 100)
    }
  }, [isOpen])
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 bg-white" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 backdrop-blur-md ${
              modelBG ? modelBG : 'bg-black/40'
            }`}
          />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${className} transform transition-all rounded-xl bg-white/5 px-16 py-6 shadow-2xl relative`}
              >
                {scroll && <div className="-mt-4" ref={scrollbarRef}></div>}
                {showCloseIcon && (
                  <div
                    ref={scrollbarRef}
                    className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
                    onClick={onClose}
                  >
                    <NextImage
                      width={20}
                      height={20}
                      src="/svgs/x-close.svg"
                      alt="close button"
                    />
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Model
