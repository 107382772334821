import React, { Fragment, useMemo, useEffect, useState } from 'react'
import HeaderButton from '@/components/button/HeaderButton'

import { Transition as ReactTransaction } from 'react-transition-group'
import { Popover, Transition } from '@headlessui/react'
import { contentStyle, contentTransitionStyles } from '@/utils/transition'

import { XIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import NextImage from '@/utils/Image'
import { SecondaryIconButton } from '../sportsidebar'
import useSidebar from '@/hooks/useSidebar'
import { getProfileSetting } from '@/firebase/utils'
import { getSportByName } from '@/constants/countries-leagues'
import { setActiveLeague, setBottomTabLabel } from '@/redux/slices/sidebar'
import { toggleBetslips, toggleBetSlipsMobile } from '@/redux/slices/betSlips'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'

const menus = [
  {
    label: 'Casino',
    icon: '/img/casino-header.webp',
    href: '',
    page: 'casino',

    games: [
      {
        gameName: 'Plinko',
        href: '/casino/plinko',
      },
      // {
      //   gameName: 'Keno',
      //   href: '',
      // },
      // {
      //   gameName: 'Lettery',
      //   href: '',
      // },
      // {
      //   gameName: 'Baccarat',
      //   href: '',
      // },
      // {
      //   gameName: 'Blackjack',
      //   href: '/casino/blackjack',
      // },
    ],
  },
  {
    label: 'Sports',
    icon: '/img/games-header.webp',
    href: '/sports/baseball',
    page: 'sports',
    child: [
      {
        icon: '/svgs/video-recorder.svg',
        label: 'Live Events',
        href: '/live',
      },
      {
        icon: '/svgs/star-colored-outlined.svg',
        label: 'Favorites',
        href: '/favourites',
      },
      {
        icon: '/svgs/bet-ticket.svg',
        label: 'My Bets',
        href: '/bets',
      },
    ],
    sportChild: [
      {
        heading: 'Popular',
        subChilds: [
          {
            icon: '/leagues/nhl.svg',
            label: 'NHL',
            href: '/sports/hockey',
          },
          {
            icon: '/leagues/nba.svg',
            label: 'NBA',
            href: '/sports/basketball',
          },
          {
            icon: '/leagues/mlb.svg',
            label: 'MLB',
            href: '/sports/baseball',
          },
        ],
      },
    ],
    sportEvents: [
      {
        icon: '/svgs/video-recorder.svg',
        label: 'Live Events',
        href: '/live',
      },
      {
        icon: '/svgs/star-colored-outlined.svg',
        label: 'Favorites',
        href: '/favourites',
      },
      {
        icon: '/svgs/bet-ticket.svg',
        label: 'My Bets',
        href: '/bets',
      },
    ],
  },
]
// const submenu = [
//   {
//     icon: '/svgs/video-recorder.svg',
//     label: 'Live Events',
//     href: '/live',
//   },
//   {
//     icon: '/svgs/star-colored-outlined.svg',
//     label: 'Favorites',
//     href: '/favourites',
//   },
//   {
//     icon: '/svgs/bet-ticket.svg',
//     label: 'My Bets',
//     href: '/bets',
//   },
// ]
const MenuNavigation = () => {
  // const labelText = useSelector((state) => state?.sidebar?.bottomTabLabel)
  const navigation = [
    // {
    //   label: 'Leaderboard',
    //   icon: '/img/leaderboard-header.webp',
    //   href: '/leaderboard',
    //   page: 'leaderboard',
    // },
    {
      icon: '/svgs/single-ticket.svg',
      label: 'MyBets',
      href: '/bets',
      page: 'bets',
    },
    // {
    //   label: 'Home',
    //   icon: '/svgs/home-hashtag.svg',
    //   href: '/home',
    //   page: 'home',
    // },
    {
      label: 'Casino',
      icon: '/img/casino-header.webp',
      href: '/casino/plinko',
      page: 'casino',
    },
    {
      label: 'Sports',
      icon: '/img/games-header.webp',
      href: '/sports/popular-events',
      page: 'sports',
    },
    // {
    //   label: labelText === 'Casino' ? 'Sports' : 'Casino',
    //   icon:
    //     labelText === 'Casino'
    //       ? '/img/games-header.webp'
    //       : '/img/casino-header.webp',
    //   href: labelText === 'Casino' ? '/sports/basketball' : '/casino/plinko',
    //   page: labelText === 'Casino' ? 'sports' : 'casino',
    // },
  ]
  return (
    <>
      {navigation.map((item, index) => (
        <HeaderButton key={index} {...item} header bottom />
      ))}
    </>
  )
}

const Accordion = ({
  title,
  center,
  children,
  icon,
  games,
  sports1,
  events,
  showIcons = true,
  onClick,
}) => {
  const { pathname, push } = useRouter()
  const [isOpen, setIsOpen] = React.useState(
    pathname === '/casino/plinko' ? title == 'Casino' : title == 'Sports',
  )

  const { activeRoute, sports, activeSport, ...rest } = useSidebar()
  const dispatch = useDispatch()

  const sportsData = useMemo(() => {
    return (
      (sports?.length && sports?.map((sport) => getSportByName(sport))) || []
    )
  }, [sports])

  const sortedSports = useMemo(() => {
    return sportsData?.sort((a, b) => a.index - b.index)
  }, [sportsData])

  const gameHandler = (href) => {
    dispatch(setActiveLeague)
    push(href)
    onClick()
  }

  const sportHandler = (sport) => {
    rest?.setActiveSport(sport)
    setTimeout(() => {
      onClick()
    }, 1000)
  }
  const onClickMenu = (title) => {
    setIsOpen(!isOpen)
    dispatch(setBottomTabLabel(title))
  }

  return (
    <div className="w-full  rounded-lg overflow-hidden">
      <div
        className="flex items-center  py-2.5 px-4"
        onClick={() => onClickMenu(title)}
      >
        <div
          className={`font-medium flex-1 text-base capitalize  flex gap-x-2 ${
            center ? 'text-center ' : 'text-left '
          }`}
        >
          <NextImage
            src={icon}
            height={24}
            width={25}
            alt="next"
            sizes="100vw"
          />
          {title}
        </div>
        <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center cursor-pointer ">
          {isOpen ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 12.5L10 7.5L5 12.5"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="px-6 py-3  ">
          <div className="flex flex-row w-full mb-2">
            {children &&
              children.map((child, idx) => (
                <div
                  key={idx}
                  className={`font-medium flex-1 text-base capitalize  mb-2 flex flex-col items-center gap-y-1 `}
                >
                  <Link
                    href={child.href}
                    className="flex flex-col items-center "
                  >
                    <NextImage
                      src={child.icon}
                      height={30}
                      width={30}
                      alt="next"
                      sizes="100vw"
                    />
                    {child.label}
                  </Link>
                </div>
              ))}
          </div>
          {games &&
            games?.map((game, idx) => (
              <div key={idx} className="py-3 -mx-4 ">
                <Link href={game?.href}>{game?.gameName}</Link>
              </div>
            ))}
          {sports1 &&
            sports1.map((child, idx) => (
              <div
                key={idx}
                className={`font-medium flex-1 text-base capitalize  mb-2  ${
                  center ? 'text-center ' : 'text-left '
                }`}
              >
                <div className="mb-4 -mx-4">{child.heading}</div>
                {child.subChilds.map((children, idx) => (
                  <div
                    key={idx}
                    className="flex gap-x-2  my-2"
                    onClick={() => gameHandler(children.href)}
                  >
                    <NextImage
                      src={children.icon}
                      height={24}
                      width={25}
                      alt="next"
                      sizes="100vw"
                    />
                    {children.label}
                  </div>
                ))}
                {/* <div className="border-t border-b -mx-6 mt-2">
                  <div className="pt-4 my-2  px-6">
                    {events.map((event, idx) => (
                      <div
                        key={idx}
                        className={`font-medium flex-1 text-base capitalize  mb-2 ${
                          center ? 'text-center ' : 'text-left '
                        }`}
                      >
                        <Link href={event.href} className="flex gap-x-2">
                          <NextImage
                            src={event.icon}
                            height={24}
                            width={25}
                            alt="next"
                            sizes="100vw"
                          />
                          {event.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div> */}
                <div className="mt-4 -mx-4">All Sports</div>
                <div className="flex flex-col w-full relative items-center -mx-4">
                  {showIcons &&
                    sortedSports?.length > 0 &&
                    sortedSports?.map((sport, index) => (
                      <SecondaryIconButton
                        key={index}
                        sport={sport}
                        activeSport={activeSport}
                        appendedData={rest}
                        isOpen={isOpen}
                        // onClick={() => rest?.setActiveSport(sport?.name)}
                        onClick={() => sportHandler(sport?.name)}
                        setActiveLeague={rest?.setActiveLeague}
                      />
                    ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

function BottomBar() {
  const { pathname } = useRouter()
  const dispatch = useDispatch()
  const { slips: listingData } = useSelector(betSlipsSelector)
  const [show, setShow] = React.useState(false)
  const [profileData, setProfileData] = useState({})
  const { user } = useSelector(authSelector)
  const isAdmin = user?.roleType === 'admin'

  const onClickMenu = () => {
    setShow(!show)
  }

  const betHandler = () => {
    dispatch(toggleBetslips())
    dispatch(toggleBetSlipsMobile())
  }

  const getProfile = async () => {
    const res = await getProfileSetting()
    if (res) {
      setProfileData(res)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <ReactTransaction in={show} timeout={300}>
      {(state) => (
        <Popover
          style={{
            ...contentStyle(300),
            ...contentTransitionStyles[state],
          }}
        >
          <Transition
            timeout={300}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            show={show}
          >
            <Popover.Panel
              focus
              className={`fixed z-30 top-0 inset-x-0 p-2 transition`}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-[#0e1411] divide-y-2 divide-gray-50  ">
                <div className="pt-5 px-2 ">
                  <div className={`flex items-center justify-between`}>
                    <Link href="/home" className="relative block">
                      <NextImage
                        src="/img/lil-bit-logo.svg"
                        alt="logo"
                        className="mr-8 cursor-pointer"
                        width={85}
                        height={85}
                      />
                    </Link>
                    <Popover.Button as={Fragment}>
                      <div
                        onClick={() => setShow(false)}
                        className={
                          'text-white rounded-lg cursor-pointer w-10 h-10 flex items-center justify-center hover:bg-[#fff]/10'
                        }
                      >
                        <XIcon className="w-5 h-5 " />
                      </div>
                    </Popover.Button>
                  </div>
                  {/* Dropdown menu in the menu*/}
                  <nav className="mb-[3rem] ">
                    <div className="overflow-auto h-[84vh] ">
                      {/* <div className="flex flex-row justify-evenly items-center text-white mt-5 my-5">
                        {[...submenu].map((item, index) => (
                          <div key={index}>
                            <div className="w-full ">
                              <Link
                                href={item.href}
                                className="flex flex-row items-center gap-x-1"
                              >
                                <NextImage
                                  src={item.icon}
                                  height={24}
                                  width={25}
                                  alt="next"
                                  sizes="100vw"
                                />
                                {item.label}
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div> */}

                      {[...menus].map((item, index) => (
                        <div key={index}>
                          <div className="flex justify-between items-center  ">
                            <Accordion
                              title={item.label}
                              icon={item.icon}
                              games={item.games}
                              sports1={item.sportChild}
                              events={item.sportEvents}
                              onClick={onClickMenu}
                            >
                              {item.child}
                            </Accordion>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
          <nav className="fixed bottom-0 flex justify-between items-center px-4  bg-white/10 h-[70px] sm:hidden backdrop-blur-md z-20 border-inherit w-full">
            <div onClick={onClickMenu}>
              <HeaderButton
                label="Menu"
                icon={'/svgs/menubar.svg'}
                header
                bottom
              />
            </div>
            <MenuNavigation />
            {(pathname === '/sports/[sport]' ||
              pathname === '/home' ||
              pathname === '/live' ||
              pathname === '/sports/[sport]/[betId]' ||
              pathname === '/sports/[sport]/props' ||
              pathname === '/sports/[sport]/futures' ||
              pathname === '/sports/popular-events') && (
              <div onClick={betHandler} className="relative" id="targetDiv">
                <HeaderButton
                  label="Bet Slip"
                  icon={'/svgs/betSlip.svg'}
                  // header
                  bottom
                />
                {!isAdmin && (
                  <div className="absolute right-3 sm:right-4 -top-2 sm:-top-1 rounded-full w-[18px] h-[18px] sm:w-[24px] sm:h-[22px] bg-[#F7941D] flex items-center justify-center py-3 px-3 text-xs sm:text-sm font-bold">
                    {listingData.length}
                  </div>
                )}
              </div>
            )}
          </nav>
        </Popover>
      )}
    </ReactTransaction>
  )
}

export default React.memo(BottomBar)
