import React from 'react'
import { getSportByName } from '@/constants/countries-leagues'
import useSidebar from '@/hooks/useSidebar'
import { toggleSidebar } from '@/redux/slices/sidebar'
import { sidebarStyle, sidebarTransitionStyles } from '@/utils/transition'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Transition } from 'react-transition-group'
import NextImage from '@/utils/Image'
import { useMediaQuery } from 'react-responsive'
import { FormattedMessage, useIntl } from 'react-intl'

const IconButtons = [
  {
    route: 'live',
    href: '/live',
    title: 'navLeftBar.live',
    defaultTitle: 'Live',
    bgColor: 'bg-[#D92D20]',
    activeImg: '/svgs/video-recorder-white.svg',
    disActiveImg: '/svgs/video-recorder.svg',
    alt: 'Live games icon',
  },
  {
    route: 'favourites',
    href: '/favourites',
    title: 'navLeftBar.favorites',
    defaultTitle: 'Favorites',
    bgColor: 'bg-[#F7941D]',
    activeImg: '/svgs/star-white-outlined.svg',
    disActiveImg: '/svgs/star-colored-outlined.svg',
    alt: 'Favourite games icon',
  },
  {
    route: 'bets',
    href: '/bets',
    title: 'navLeftBar.myBets',
    defaultTitle: 'My Bets',
    bgColor: 'bg-[#008000]',
    activeImg: '/svgs/white-ticket.svg',
    disActiveImg: '/svgs/bet-ticket.svg',
    alt: 'Bets icon',
  },
]

const shrinkText = (text, length = 10) => {
  if (text?.length <= length) return text
  return text.slice(0, length) + '...'
}

const IconButton = ({
  icon: Icon,
  href,
  bgColor = 'bg-[#000000]/5',
  ischeck,
  isOpen,
  ...rest
}) => {
  return (
    <Link href={href || '#'} prefetch={false}>
      <button
        className={`text-white h-12 flex items-center rounded-xl ${
          ischeck ? bgColor : 'mouse-pointer'
        } ${isOpen ? 'px-4 w-[130px]' : 'justify-center w-[48px]'}`}
        {...rest}
      >
        <Icon isActive={ischeck} />
        {isOpen && <p className="ml-4">{rest.title}</p>}
      </button>
    </Link>
  )
}

export const SecondaryIconButton = ({
  sport,
  activeSport,
  appendedData,
  isOpen,
  setActiveLeague,

  ...rest
}) => {
  const activeLeagues = [
    ...((appendedData?.leagues &&
      activeSport === sport.name &&
      appendedData?.leagues[sport?.name]) ||
      []),
  ]
  const sm = useMediaQuery({ query: '(max-width: 639px)' })

  const intl = useIntl()

  return (
    <>
      <div
        className={`text-white my-1 flex items-center max-w-[calc(100%-10px)] relative  ${
          activeSport === sport.name
            ? `bg-[#008000]`
            : 'hover:bg-[#000000]/5 hover:backdrop-blur-sm'
        } cursor-pointer rounded-lg p-3 ${isOpen && 'w-full'}`}
        title={sport.name.charAt(0).toUpperCase() + sport.name.slice(1)}
        {...rest}
      >
        <div className="relative">
          <NextImage
            alt={sport.name}
            src={sport.logo}
            width={
              sport.name === 'mma'
                ? 26
                : sport.name === 'football' || sport.name === 'basketball'
                ? 22
                : 24
            }
            height={
              sport.name === 'mma'
                ? 26
                : sport.name === 'football' || sport.name === 'basketball'
                ? 22
                : 24
            }
            style={{ objectFit: 'cover' }}
          />
        </div>
        {isOpen && (
          <p className="ml-1">
            {sport.name.charAt(0).toUpperCase() + sport.name.slice(1)}
          </p>
        )}
      </div>
      {activeLeagues?.map((league, index) => {
        const isActive =
          league?.name?.toLowerCase() ===
          appendedData?.activeLeague?.toLowerCase()
        return (
          <div
            key={index}
            className={`text-white mx-1 overflow-hidden flex items-center relative ${
              isActive && !sm
                ? `bg-[#313439] border-2 border-[#008000] shadow-md shadow-[#008000]/30`
                : 'hover:bg-[#000000]/5 hover:backdrop-blur-sm'
            } cursor-pointer rounded-lg max-w-[calc(100%-10px)] ${
              isOpen && 'w-full'
            }`}
            onClick={() => setActiveLeague(league.name)}
            title={league.name}
          >
            <NextImage
              src={league?.logo}
              alt={`${league.name} league`}
              className="w-12 h-12"
              width={48}
              height={48}
            />
            {isOpen && (
              <p className="ml-4 text-sm flex-1 whitespace-nowrap">
                {sm
                  ? league.name
                  : shrinkText(
                      league.name.charAt(0).toUpperCase() +
                        league.name.slice(1),
                      5,
                    )}
              </p>
            )}
          </div>
        )
      })}
    </>
  )
}

const RandomLeagues = memo(
  ({ isOpen, activeLeague, sport, appendedData, activeSport, ...data }) => {
    const getAllLeagues = () => {
      const tempData = []
      const sports = [...new Set(Object.keys(data?.leagues || {}) || [])]

      sports?.forEach((sport) => {
        const leagues = data?.leagues[sport]
        leagues?.forEach((league) => {
          tempData?.push({
            ...league,
            sport: sport,
            visitIndex:
              data?.visitedLeagues?.[league?.name?.toLowerCase()] || 0,
          })
        })
      })
      return tempData
        ?.sort((a, b) => {
          return a?.visitIndex - b?.visitIndex
        })
        ?.reverse()
        ?.slice(0, 5)
    }

    const allLeagues = getAllLeagues()
    return (
      <div className="py-10 space-y-2 border-b border-white/10 w-full flex flex-col items-center">
        {allLeagues?.map((league, index) => (
          <div
            className={`relative text-white hover:bg-white/5 cursor-pointer flex items-center rounded-lg  max-w-[calc(100%-10px)] ${
              league?.name?.toLowerCase() ===
              appendedData?.activeLeague?.toLowerCase()
                ? `bg-[#313439] shadow-md shadow-[#008000]/30 `
                : 'hover:bg-[#000000]/5 hover:backdrop-blur-sm'
            }  ${isOpen && 'w-full'}`}
            onClick={() => {
              data?.setActiveSport(league.sport)
              setTimeout(() => {
                data?.setActiveLeague(league.name)
              }, 100)
            }}
            title={league.name}
            key={index + league.name}
          >
            <NextImage
              priority={league?.name.toLowerCase() === 'ufc'}
              src={league?.logo}
              alt={`${league.name} league`}
              className="h-12"
              width={48}
              height={48}
            />
            {isOpen && (
              <span className={`${'ml-4'} text-sm`}>
                {shrinkText(
                  league.name.charAt(0).toUpperCase() + league.name.slice(1),
                  5,
                )}
              </span>
            )}
          </div>
        ))}
      </div>
    )
  },
)
RandomLeagues.displayName = 'RandomLeagues'

function Sportsidebar({ showIcons = true, isOpen }) {
  const dispatch = useDispatch()

  const { activeRoute, sports, activeSport, ...rest } = useSidebar()
  const sportsData = useMemo(() => {
    return (
      (sports?.length && sports?.map((sport) => getSportByName(sport))) || []
    )
  }, [sports])

  const sortedSports = useMemo(() => {
    return sportsData?.sort((a, b) => a.index - b.index)
  }, [sportsData])

  const handleSidebar = () => {
    dispatch(toggleSidebar())
  }

  const intl = useIntl()
  return (
    <Transition in={isOpen} timeout={50}>
      {(state) => (
        <div
          style={{
            ...sidebarStyle(50),
            ...sidebarTransitionStyles[state],
            overflowX: 'hidden',
            msOverflowX: 'hidden',
          }}
          className={`scroller fixed hidden sm:flex text-white flex-col bg-[#fff]/5 backdrop-blur-sm h-full max-h-full border-r border-white/20  ${
            !isOpen && 'w-20'
          } items-center overflow-auto z-20 min-h-screen border-2`}
        >
          <Link href="/home" prefetch={false}>
            <div className="mt-4 cursor-pointer h-[40px]">
              <NextImage
                src={isOpen ? '/img/lil-bit-logo.svg' : '/img/lil-bit-icon.svg'}
                alt="Lilbit logo"
                width={isOpen ? 100 : 40}
                height={isOpen ? 80 : 40}
              />
            </div>
          </Link>

          <div className="py-10 space-y-2 border-b border-white/10 w-full flex flex-col items-center ">
            {IconButtons.map((Iconbutton, index) => {
              return (
                <IconButton
                  key={index}
                  isOpen={isOpen}
                  ischeck={activeRoute === Iconbutton.route}
                  href={Iconbutton.href}
                  // title={Iconbutton.title}
                  title={intl.formatMessage({
                    id: Iconbutton.title,
                    defaultMessage: Iconbutton.defaultTitle,
                  })}
                  bgColor={Iconbutton.bgColor}
                  icon={(props) => (
                    <div className="flex items-center justify-center">
                      <NextImage
                        src={
                          props?.isActive
                            ? `${Iconbutton.activeImg}`
                            : `${Iconbutton.disActiveImg}`
                        }
                        alt={Iconbutton.alt}
                        width={24}
                        height={24}
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                  )}
                />
              )
            })}
          </div>

          <RandomLeagues
            {...rest}
            isOpen={isOpen}
            appendedData={rest}
            activeSport={activeSport}
            sport={sortedSports}
          />

          <div className="pt-10 w-full flex flex-col h-full flex-1 ">
            <div className="flex flex-col w-full relative items-center">
              {showIcons &&
                sortedSports?.length > 0 &&
                sortedSports?.map((sport, index) => (
                  <SecondaryIconButton
                    key={index}
                    sport={sport}
                    activeSport={activeSport}
                    appendedData={rest}
                    isOpen={isOpen}
                    onClick={() => rest?.setActiveSport(sport?.name)}
                    setActiveLeague={rest?.setActiveLeague}
                  />
                ))}
            </div>
            <div
              onClick={handleSidebar}
              className={`text-white hover:bg-[#000000]/5 hover:backdrop-blur-sm cursor-pointer flex items-center rounded-xl mx-auto mt-4 p-3`}
            >
              {isOpen && (
                <p className="mr-4">
                  <FormattedMessage
                    id="navLeftBar.collapse"
                    defaultMessage="Collapse"
                  />
                </p>
              )}
              <NextImage
                className={`${isOpen ? 'rotate-180' : 'rotate-0'}`}
                src="/svgs/sidebarright.svg"
                alt="Sidebar open collapse icon"
                width={24}
                height={24}
              />
            </div>
            <div className="h-4">&nbsp;</div>
          </div>
        </div>
      )}
    </Transition>
  )
}

Sportsidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  // setSidebarOpen: PropTypes.func.isRequired,
  footballIcon: PropTypes.bool.isRequired,
}

export default React.memo(Sportsidebar)
