import { REACT_APP_NOVU_APPLICATION_IDENTIFIER } from '@/constants/environment'
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from '@novu/notification-center'
import { getAuth } from 'firebase/auth'
import { useRouter } from 'next/router'

function Index() {
  const router = useRouter()
  const auth = getAuth()

  function onNotificationClick(notification) {
    router.push(notification.cta.data.url)
  }

  return (
    <NovuProvider
      subscriberId={`${auth?.currentUser ? auth?.currentUser?.uid : 'lilbit'}`}
      applicationIdentifier={REACT_APP_NOVU_APPLICATION_IDENTIFIER}
      backendUrl="https://notifications.relysia.com/connect"
    >
      <PopoverNotificationCenter
        onNotificationClick={onNotificationClick}
        onActionClick={onNotificationClick}
      >
        {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      </PopoverNotificationCenter>
    </NovuProvider>
  )
}

export default Index
