import React, { useState } from 'react'

const ChoiceButton = ({ color, selected, onClick, title }) => (
  <button
    className={`m-2 w-10 sm:w-9 md:w-12 h-7 rounded-lg shadow-md ${
      selected ? 'shadow-inner' : ''
    } ${
      selected
        ? 'cursor-default border-2 border-white'
        : 'cursor-pointer border-none'
    }`}
    style={{ background: color }}
    onClick={onClick}
    title={title}
  ></button>
)

export default function ColorChoiceToggle({
  choices,
  onToggle,
  initialChoice,
}) {
  const labels = Object.keys(choices)
  const [selected, setSelected] = useState(initialChoice)

  const handleClick = (label) => {
    setSelected(label)
    onToggle(label)
  }

  return (
    <div className="flex flex-wrap overflow-hidden">
      {labels.map((l) => (
        <ChoiceButton
          key={l}
          selected={selected === l}
          color={choices[l]}
          onClick={() => handleClick(l)}
          title={`Set to ${l}`}
        />
      ))}
    </div>
  )
}
