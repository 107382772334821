import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {
  getDatabase,
  ref,
  onValue,
  off,
  push,
  query,
  orderByKey,
  limitToLast,
} from 'firebase/database'
import { errorMsg, warnMsg, successMsg } from '@/utils/toast'
import NextImage from '@/utils/Image'
import AboutModal from './aboutModal'
import ColorsModal from './colorsModal'
import {
  firebasaSendComplaints,
  firebaseRemoveUserOptions,
  firebaseSendtoAdmin,
  firebaseUpdateUserOptions,
} from '@/firebase/utils'
import { nameColors, textColors } from './config'
import ReportModal from './reportModal'
import Block from '@/icons/block'
import Report from '@/icons/report'
import BlockUserModal from './blockUserModal'
import { setUserBlockList } from '@/redux/slices/auth'
import SettingsDropdown from './settingsDropdown'
import UnbanModal from './unbanModal'

const db = getDatabase()
const Chat = ({ user, paymail }) => {
  const tag = '@mods'
  const dispatch = useDispatch()
  const [emojiPickerShown, setEmojiPickerShown] = useState(false)
  const [unbanModal, setUnbanModal] = useState(false)
  const [colorsModal, setColorsModal] = useState(false)
  const [reportModal, setReportModal] = useState({
    status: false,
    message: null,
  })
  const [blockModal, setBlockModal] = useState({
    status: false,
    message: null,
  })
  const [nameColor, setNameColor] = useState(
    user?.chatOptions?.nameColor || 'white',
  )
  const [textColor, setTextColor] = useState(
    user?.chatOptions?.textColor || 'gray',
  )
  const [text, setText] = useState('')
  const scrollbarRef = useRef(null)
  const [messagesList, setMessagesList] = useState({})
  const [reason, setReason] = useState('')

  const handleClickOutside = (e) => {
    if (emojiPickerShown) {
      setEmojiPickerShown(false)
    }
  }
  const handleEmojiClick = (e) => {
    e.stopPropagation()
    setEmojiPickerShown(!emojiPickerShown)
  }

  const onEmojiClick = (e) => {
    const sym = e.unified.split('-')
    const codeArray = []
    sym.forEach((el) => codeArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codeArray)
    setText(text + emoji)
    setEmojiPickerShown(false)
  }

  const timeHandler = (time) => {
    const lastTime = Date.now() - time
    if (lastTime < 1000) {
      return 'Just Now'
    } else if (lastTime < 60000) {
      return `${Math.floor(lastTime / 1000)} seconds`
    } else if (lastTime < 3600000) {
      return `${Math.floor(lastTime / 60000)} minutes`
    } else if (lastTime < 86400000) {
      return `${Math.floor(lastTime / 3600000)} hours`
    } else {
      return `${Math.floor(lastTime / 86400000)} days`
    }
  }

  useEffect(() => {
    scrollbarRef.current?.scrollIntoView()
  }, [messagesList])

  const addMessages = (e) => {
    if (!user?.uid) return warnMsg('You must be logged in to send messages.')
    if (text.trim() === '')
      return warnMsg('Message must be at least 1 character long.')
    const date = Date.now()
    const createdDate = new Date()
    const messages = {
      uid: user.uid,
      username: user.username,
      photoPATH: user.photoPATH,
      paymail: paymail,
      text,
      time: date,
      createdDate,
      chatOptions: {
        nameColor,
        textColor,
      },
    }
    const chatRef = ref(db, 'chat')
    push(chatRef, messages)
    if (text.includes(tag)) {
      firebaseSendtoAdmin(messages)
    }
    setText('')
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addMessages()
    }
  }

  useEffect(() => {
    const chatRef = ref(db, 'chat')
    const qry = query(chatRef, orderByKey(), limitToLast(100))
    onValue(
      qry,
      (snapshot) => {
        const dbMessages = snapshot.val()
        if (dbMessages) {
          setMessagesList(dbMessages)
        }
      },
      (error) => {
        console.error('Error fetching plinko data:', error)
      },
    )
    return () => {
      off(qry)
    }
  }, [])

  const textHandler = (e) => {
    setText(e.target.value)
  }

  const highlightMessages = (message) => {
    const {
      text,
      chatOptions: { textColor: textC },
    } = message

    const linkRegex = /(https?:\/\/[^\s]+)/g
    const usernameRegex = /@([A-Za-z0-9]+)/g

    const parts = text.split(linkRegex)

    const finalText = parts
      .map((part) => {
        if (part.match(linkRegex)) {
          return `<a class="break-all bg-gray-700 font-bold text-white" href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`
        } else {
          return part.replace(usernameRegex, (match, username) => {
            const isCurrentUser =
              user && username.toLowerCase() === user.username.toLowerCase()
            const className = isCurrentUser
              ? 'text-green-700 break-all bg-white font-bold'
              : match === tag
              ? 'font-bold break-all text-white bg-red-700'
              : 'font-bold break-all'

            return `<span class="${className}">${match}</span>`
          })
        }
      })
      .join('')

    return (
      <span
        style={{ color: textColors[textC] }}
        className="break-all"
        dangerouslySetInnerHTML={{ __html: finalText }}
      />
    )
  }

  const highlightUsernames = (message) => {
    const {
      username,
      chatOptions: { nameColor: nameC },
    } = message
    return (
      <span style={{ color: nameColors[nameC] }} className="font-bold">
        {username}
      </span>
    )
  }

  const onSave = (chatOptions) => {
    if (user) {
      firebaseUpdateUserOptions(user?.uid, chatOptions, 'chatOptions').then(
        (res) => {
          if (res) {
            setNameColor(chatOptions.nameColor)
            setTextColor(chatOptions.textColor)
            setColorsModal(false)
            successMsg('Saved successfuly')
          } else errorMsg('Failed to save')
        },
      )
    } else {
      errorMsg('Please log in to access this feature.')
    }
  }

  const reportHandler = (message) => {
    setReportModal({
      status: true,
      message,
    })
  }

  const blockHandler = (message) => {
    setBlockModal({
      status: true,
      message,
    })
  }

  const reportUser = () => {
    if (reason.length < 10 || reason.length > 50) {
      return warnMsg(
        'Please ensure your complaint is between 10 and 50 characters in length. Provide a concise description to help us address your concerns effectively. Thank you for your cooperation.',
      )
    }
    firebasaSendComplaints({
      ...reportModal.message,
      reason,
      senderUid: user.uid,
    }).then((res) => {
      if (!res?.error) {
        setReportModal({
          status: false,
          message: null,
        })
        setReason('')
        return successMsg(
          'Your message has been successfully forwarded to us. Thank you for reaching out; we appreciate your communication.',
        )
      } else {
        console.log(res?.error)
        return errorMsg(
          'An error occurred during the transaction. We apologize for the inconvenience. Our team has been notified and will address it as soon as possible. Thank you for your understanding.',
        )
      }
    })
  }

  const blockUser = (blockOptions) => {
    if (user) {
      firebaseUpdateUserOptions(user?.uid, blockOptions, 'blockedUsers').then(
        (res) => {
          if (!res?.error) {
            dispatch(setUserBlockList(res))
            setBlockModal({
              status: false,
              message: null,
            })
            successMsg('Blocked successfuly')
          } else errorMsg('Failed to block')
        },
      )
    } else {
      errorMsg('Please log in to access this feature.')
    }
  }

  const onColorsOpen = () => setColorsModal(true)
  const onUnbanOpen = () => setUnbanModal(true)
  const onBlockUser = (uid) => {
    if (user) {
      firebaseRemoveUserOptions(user?.uid, uid).then((res) => {
        if (!res?.error) {
          dispatch(setUserBlockList(res))
          successMsg('Unblock successfuly')
        } else errorMsg('Failed to unblock')
      })
    } else {
      errorMsg('Please log in to access this feature.')
    }
  }

  return (
    <div class="flex-1 relative p-6 flex flex-col bg-black/80 min-w-full w-full sm:w-96 h-[calc(100dvh-72px)] left-0 right-0 bottom-0">
      <div class="flex mt-2 sm:items-center justify-between py-3 border-b-2 border-gray-200">
        <AboutModal />
        <ColorsModal
          onSave={onSave}
          nameColor={nameColor}
          textColor={textColor}
          isOpen={colorsModal}
          setIsOpen={setColorsModal}
        />
        <SettingsDropdown colorsModal={onColorsOpen} unbanModal={onUnbanOpen} />

        <ReportModal
          onSend={reportUser}
          reason={reason}
          setReason={setReason}
          isOpen={reportModal.status}
          message={reportModal.message}
          setIsOpen={setReportModal}
        />
        <BlockUserModal
          onSend={blockUser}
          isOpen={blockModal.status}
          message={blockModal.message}
          setIsOpen={setBlockModal}
        />
        <UnbanModal
          onSend={onBlockUser}
          isOpen={unbanModal}
          blockedList={user?.blockedUsers}
          setIsOpen={setUnbanModal}
        />
      </div>
      <ul
        id="messages"
        class="flex flex-col flex-1 space-y-4 p-3 overflow-y-auto"
      >
        {Object.values(messagesList).length > 0 &&
          Object.values(messagesList).map((message, key) => {
            if (!user?.blockedUsers?.hasOwnProperty(message.uid)) {
              return (
                <li key={key} className="flex space-x-2">
                  <div className="w-8 h-8 relative shrink-0">
                    <NextImage
                      src={message.photoPATH}
                      className="object-cover rounded-full"
                      fill
                      alt="profile-image"
                    />
                  </div>
                  <div className="mt-2 w-full flex flex-col space-y-2 group/container">
                    <div className="text-xs h-7 w-full font-semibold flex items-center justify-between space-x-2">
                      <div className="flex space-x-2">
                        <span>{highlightUsernames(message)}</span>
                        <span class="bg-gray-700 text-gray-300 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">
                          {timeHandler(message.time)}
                        </span>
                      </div>
                      <div
                        className={`${
                          user && message.uid !== user.uid
                            ? 'space-x-2 group-hover/container:flex hidden'
                            : 'hidden'
                        }`}
                      >
                        <button
                          onClick={() => reportHandler(message)}
                          className="border group/report text-white border-gray-700 hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center"
                        >
                          <Report
                            className={`text-gray-300 group-hover/report:text-gray-800 group-hover/report:stroke-gray-800 w-4 h-4`}
                          />
                        </button>
                        <button
                          onClick={() => blockHandler(message)}
                          className="border group/block text-gray-300 border-gray-700 hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center"
                        >
                          <Block
                            className={`text-gray-300 group-hover/block:text-gray-800 group-hover/block:stroke-gray-800 w-4 h-4`}
                          />
                        </button>
                      </div>
                    </div>

                    <div class="flex flex-col flex-1 text-xs  items-start">
                      <div className="flex flex-col">
                        <div class="px-4 py-2 flex flex-col text-left rounded-lg text-sm font-semibold bg-gray-300">
                          {highlightMessages(message)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            }
          })}
        <div ref={scrollbarRef} />
        {emojiPickerShown && (
          <div className="absolute bottom-20 left-4">
            <Picker
              data={data}
              onClickOutside={handleClickOutside}
              onEmojiSelect={onEmojiClick}
            />
          </div>
        )}
      </ul>
      <div class="border-t-2 border-gray-200  pt-4 mb-2 sm:mb-0">
        <div class="relative flex">
          <input
            type="text"
            value={text}
            onChange={textHandler}
            onKeyDown={handleKeyDown}
            placeholder="Write your message!"
            class="w-full pr-20 focus:outline-none focus:outline-offset-0 border-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600  bg-gray-200 rounded-md py-3"
          />
          <div class="absolute right-0 items-center inset-y-0 flex">
            <button
              type="button"
              onClick={handleEmojiClick}
              class="inline-flex items-center justify-center rounded-full transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-6 w-6 text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </button>

            <button
              type="button"
              onClick={addMessages}
              class="inline-flex items-center justify-center rounded-md px-1 transition duration-500 ease-in-out text-cs-green focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="h-6 w-6 ml-2 transform rotate-90"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat
