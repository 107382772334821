import React, { useState, memo, useEffect } from 'react'
import { Listbox } from '@headlessui/react'
import NextImage from '@/utils/Image'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'

const viewsTypes = [
  {
    value: 'register',
    label: 'Register Users',
    href: '/admin/users',
  },
  {
    value: 'bets',
    label: 'All Bets',
    href: '/admin/bets',
  },
  {
    value: 'teams',
    label: 'All Teams Bet',
    href: '/admin/allteams',
  },
  {
    value: 'livegames',
    label: 'Live Games',
    href: '/admin/livegames',
  },
  {
    value: 'games',
    label: 'All Games Bet',
    href: '/admin/allgames',
  },
  {
    value: 'payouts',
    label: 'Payouts',
    href: '/admin/payouts',
  },
  {
    value: 'dailytracks',
    label: 'Daily Tracks',
    href: '/admin/dailytracks',
  },
]

export const ChangeMenuType = memo(({ activeMenuType, onChange }) => {
  const [activeType, setType] = useState(activeMenuType)
  const { push } = useRouter()
  const handleOnClick = (href) => {
    if (href === '#') return rest?.onClick
    push(href)
  }

  useEffect(() => {
    if (window.location.href.includes('admin')) {
      onChange && onChange(activeType)
    } else {
      setType('register')
    }
  }, [activeType])

  return (
    <Listbox value={activeType} onChange={setType}>
      <div className="relative">
        <Listbox.Button className="flex flex-row items-center gap-2 py-2.5 px-4 cursor-pointer rounded-lg bg-white/5 text-sm">
          <span className="text-base font-medium">
            Admin Panel
          </span>
          <NextImage
            width={20}
            height={20}
            src="/svgs/arrow-down-white.svg"
            alt="arrow-down"
          />
        </Listbox.Button>
        <Listbox.Options className="absolute w-full bg-[#10141A] rounded-lg p-2 border border-white/10 space-y-0.5">
          {viewsTypes.map((type, index) => (
            <Listbox.Option
              key={type.value + index}
              value={type.value}
              className={`p-2 rounded-lg font-medium text-sm ${
                activeType === type.value
                  ? 'green-mouse-pointer'
                  : 'cursor-pointer hover:bg-white/5'
              }`}
              onClick={() => handleOnClick(type.href)}
            >
              {type.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
})
ChangeMenuType.displayName = 'ChangeMenuType'
